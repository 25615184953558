<template>
  <div class="checkout">
    <el-container>
      <el-main class="checkout-box">
        <div class="header">
          <div class="header-title">结算中心</div>
          <div class="order-steps">
            <el-steps align-center :active="active">
              <el-step title="提交订单"></el-step>
              <el-step title="支付订单"></el-step>
              <el-step title="支付完成"></el-step>
            </el-steps>
          </div>
        </div>
        <div class="content">
          <div class="checkout-sku-list">
            <div class="checkout-header">
              <div class="sku-title">
                <span>票种信息</span>
              </div>
              <div class="sku-price">
                <span>单价</span>
              </div>
              <div class="sku-qty">
                <span>数量</span>
              </div>
              <div class="sku-promotion">
                <span>优惠方式</span>
                
              </div>
              <div class="sku-total">
                <span>小计</span>
              </div>
            </div>
            <div class="store-list">
              <div class="store-item">
                <div class="sku-title-col">
                  <div class="sku-title">
                    <div class="sku-image">
                      <img
                        src="https://static.foodtalks.cn/image/ecommerce/product/70c1099e98851b2914572ba7ba256428de44.png"
                        alt="封面图"
                      />
                    </div>
                    <div class="basic-info">
                      <div class="sku-name-wrap">
                        <div class="sku-name one-line">
                          {{ ticketInfo.name }}
                        </div>
                        <!---->
                      </div>
                      <div class="sku-description one-line">
                        论坛（全体大会+分论坛）、展区、赛事颁奖典礼。
                      </div>
                      <div class="sku-price-qty font-small">
                        <span class="sku-price">5800元</span>
                      <el-input-number
            v-model="num"
            @change="handleChange"
            :min="1"
            :max="99"
            size="small"
            label="购票数量"
          ></el-input-number>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sku-price-col">
                  <div class="sku-price middle" style="height: 100%">
                    <span>{{ ticketInfo.price }}元</span>
                  </div>
                </div>
                <div class="sku-qty-col">
                  <div class="sku-price middle" style="height: 100%">
                    <el-input-number
                      size="mini"
                      v-model="num"
                      @change="handleChange"
                      :min="1"
                      :max="99"
                      label="购票数量"
                    ></el-input-number>
                  </div>
                </div>
                <div class="sku-promotion-col middle">
                  <div class="sku-promotion"><span v-show="originalPrice_show">  {{num}}件{{discount_zheNow}}折，优惠{{originalPrice}}元</span></div>
                </div>
                <div class="sku-total-col middle">
                  <span class="label font-small">小计</span>
                  <div class="sku-total">{{ totalPrice }}元</div>
                </div>
              </div>
            </div>
            <div class="select-payment-method">
              <div class="left-part">
                <div class="title">支付方式</div>
                <div class="tip">（请先选择支付方式，再提交订单）</div>
              </div>
              <div class="right-part">
                <el-radio v-model="payType" label="1" border>
                  <div class="online-payment">
                    <div class="title">微信支付</div>
                  </div>
                </el-radio>
                <!-- <el-radio v-model="payType" label="2" border
                  >公对公转账</el-radio
                > -->
              </div>
            </div>
            <div class="product-total">
              <div class="content">
                <div class="content-right">
                  <div class="price-label">
                    <span class="label">总计原价</span
                    ><span class="value">{{ totalPriceNoDiscount }}元</span>
                  </div>
                    <div class="price-label" v-show="originalPrice_show">
                    <span class="label">已享优惠</span
                    ><span class="value">- {{ originalPrice }}元</span>
                  </div>
                  <div class="price-label total-payment">
                    <span class="label">应付金额</span
                    ><span class="value">{{ totalPrice }}元</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-bar-wrap">
              <div class="footer-bar-pc">
                <div class="confirm-btn">
                  <el-button type="primary" @click="goPay">提交订单</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "checkout",
  components: {},
  data() {
    return {
      num: 1,
      active: 1,
      payType: "1",
      totalPrice: "",
      totalPriceNoDiscount:"",
      discount_zheNow:"",
      originalPrice:"",
      originalPrice_show:false,
      ticketInfo: {},
    };
  },
  created() {
    this.getChoseTicketInfo();
    // this.getTicket()
   
    this.getTotalPrice(this.num);
  },
  methods: {
    getChoseTicketInfo() {
      this.ticketInfo = JSON.parse(window.sessionStorage.getItem("ticketInfo"));
      console.log(this.ticketInfo);
       this.num = window.sessionStorage.getItem("choseTicketNum");
      // this.totalPrice = window.sessionStorage.getItem("ticketTotalPrice");
    },
    handleChange(value) {
      this.num = value;
      this.getTotalPrice(this.num);
    },
    getTotalPrice(num) {
        this.totalPriceNoDiscount = this.ticketInfo.price * num
      if (num < 5) {
        this.totalPrice = this.ticketInfo.price * num;
    this.originalPrice_show =false
      } else if (5 <= num < 10) {
        this.totalPrice = this.ticketInfo.price * num * 0.9;
        this.originalPrice = this.ticketInfo.price * num * 0.1;
       this.originalPrice_show =true
        this.discount_zheNow = 9
      } if(this.num >= 10) {
        this.totalPrice = this.ticketInfo.price * num * 0.8;
        this.originalPrice = this.ticketInfo.price * num * 0.2;
     this.originalPrice_show =true
            this.discount_zheNow = 8
      }
    },
    goPay() {
      window.sessionStorage.setItem("choseTicketNum", this.num);
      this.$router.push("/pay");
    },
  },
};
</script>

<style lang="less" scoped>
.checkout-box {
  // display: flex;
  max-width: 1200px;
  min-height: 485px;
  margin: 32px auto;
  padding: 24px;
  border: 1px solid #eaeaea;
  background: #fff;
  .header {
    font-size: 18px;
    color: #333;
    width: 100%;
    font-weight: 700;
    margin: 16px 0 34px 0;
    position: relative;
    .order-steps {
      position: absolute;
      width: 360px;
      right: 0;
      top: -10px;
      .el-steps {
        font-weight: 400;
        justify-content: center;
        /deep/.is-finish {
          color: #003e89;
          border-color: #003e89;
          .el-step__icon {
            background: #003e89;
            color: #fff;
          }
        }

        /deep/.el-step__title {
          font-size: 12px;
          line-height: 26px;
        }
      }
    }
  }
  .content {
    .checkout-header {
      height: 50px;
      color: #333;
      line-height: 50px;
      font-size: 14px;
      background: #f2f9fe;
      display: flex;
      padding-left: 16px;
      .sku-title {
        width: 42.5%;
        text-align: center;
      }
      .sku-price {
        width: 14.3%;
        text-align: center;
      }
      .sku-qty {
        width: 14.3%;
        text-align: center;
      }
      .sku-promotion {
        width: 14.3%;
        text-align: center;
      }
      .sku-total {
        width: 14.3%;
        text-align: center;
      }
    }
    .store-list {
      margin-top: 8px;
      padding-left: 16px;
      color: #333;
      background: #f2f9ff;
      border: 1px solid #d3eafa;
      .middle {
        display: flex;

        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-right: 1px solid #d3eafa;
        align-items: center;
      }

      .store-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 14px;
        .sku-title-col {
          width: 42.5%;
          .sku-title {
            display: flex;
            padding: 12px 0;
            border-right: 1px solid #d3eafa;
            .sku-image {
              -ms-flex-negative: 0;
              flex-shrink: 0;
              width: 100px;
              height: 67px;
              img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
              }
            }
            .basic-info {
              display: flex;
              margin-left: 12px;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              padding-right: 20px;
              .sku-name {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
              }
              .sku-description {
                color: #999;
                line-height: 20px;
                font-size: 14px;
              }
              .sku-price-qty {
                display: none;
              }
              .sku-name-wrap {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
              }
            }
          }
        }
        .sku-price-col {
          width: 14.3%;
          text-align: center;
        }
        .sku-qty-col {
          width: 14.3%;
          text-align: center;
          .el-input-number--mini {
            width: 80%;
            line-height: 26px;
          }
        }
        .sku-promotion-col {
          width: 14.3%;
          text-align: center;
        }
        .sku-total-col {
          width: 14.3%;
          text-align: center;
          color: red;
          font-weight: 700;
          .label {
            display: none;
          }
        }
      }
    }
    .select-payment-method {
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: #f2f9ff;
      margin-top: 16px;
      padding: 0 16px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 85px;
      .left-part {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: 700;
          color: #333;
        }
        .tip {
          font-size: 12px;
          color: #999;
          margin-left: 16px;
        }
      }
      .right-part {
        display: flex;
        .el-radio {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          // align-items: center;
          width: 125px;
          height: 40px;
          background: #fff;
          border: 2px solid #b1b1b1;
        }
      }
    }
    .product-total {
      padding: 16px 0 12px;
      background: #fff;
      .content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        .content-right {
          padding-top: 35px;
          .price-label {
            font-size: 16px;
            color: #333;
            margin-bottom: 16px;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            text-align: right;
            line-height: 22px;
            .value {
              width: 115px;
              font-weight: 700;
            }
          }
          .total-payment {
            margin-top: 25px;
            margin-bottom: 18px;
            font-size: 18px;
            font-weight: 700;
            .value {
              color: red;
            }
          }
        }
      }
    }
    .footer-bar-wrap {
      .footer-bar-pc {
        max-width: 1200px;
        margin: 0 auto;
        .confirm-btn {
          text-align: right;
          padding: 12px 0;
          .el-button {
            background: #003e89;
            border-radius: 2px;
            min-width: 120px;
            height: 40px;
            padding: 12px 2px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .checkout-box {
    margin: 0 auto;
    .header {
      .header-title {
        display: none;
      }
      /deep/.order-steps {
        position: relative;
        width: 100%;
      }
    }
    .content {
      .checkout-sku-list {
        .checkout-header {
          display: none;
        }
        .store-list {
          border: none;
          background: #fff;
          margin-top: 0;
          padding: 0;
          .middle {
            display: flex;
            flex-direction: initial;
            align-items: center;
            border-right: 0px;
          }
          .store-item {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin-bottom: 22px;
            border: none;
            .sku-title-col {
              width: 100%;
              background: #f2f9ff;
              margin-bottom: 8px;
              .sku-title {
                display: flex;
                padding: 0.75rem 0;
                border-right: 0.0625rem;
                .basic-info {
                  .sku-price-qty {
                    display: block;
                    margin-top: 8px;
                    width: 100%;
                    display: flex;
                    line-height: 20px;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                  }
                }
              }
            }
            .sku-price-col {
              display: none;
            }
            .sku-qty-col {
              display: none;
            }
            .sku-total-col {
              display: flex;
              -webkit-box-pack: end;
              -ms-flex-pack: end;
              justify-content: flex-end;
              // padding: 0 16px;
              width: 100%;
              text-align: right;
              color: red;
              font-weight: 700;
              .label {
                display: block;
                margin-right: 16px;
                -ms-flex-negative: 0;
                flex-shrink: 0;
                font-weight: 700;
                line-height: 20px;
              }
            }
            .sku-list {
              width: 100%;
              padding: 16px 16px 0;
              margin-bottom: 8px;
              background: #f2f9ff;
            }
          }
        }
        .select-payment-method {
          margin-top: 32px;
          padding: 16px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          height: unset;
          .left-part {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            width: 100%;
            align-items: center;
            .title {
              font-size: 16px;
              font-weight: 700;
            }
            .tip {
              font-size: 12px;
              margin-left: 8px;
            }
          }
          .right-part {
            display: flex;
            width: 100%;
            margin-top: 18px;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
</style>